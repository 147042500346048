



































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import { JobDto } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";
@Component({
  name: "JobList",
  components: {
    PagedTableView,
    AbSelect,
    ExportButton,
  },
})
export default class JobDtoList extends Vue {
  detailId = 0;
  queryForm = {};

  // 获取表数据
  fetchData(params: any) {
    return api.job.getAll(params);
  }

  // 新建
  handleCreate() {
    this.$router.push({ name: "jobMaintainCreate" });
  }

  // 查看详情
  jumpDetail(row: JobDto) {
    this.detailId = row.id!;
    this.$router.push({
      name: "jobMaintainDetail",
      params: { id: row.id!.toString() },
    });
  }

  // 编辑
  handleEdit(row: JobDto) {
    this.$router.push({
      name: "jobMaintainEdit",
      params: { id: row.id!.toString() },
    });
  }

  // 删除
  async handleDelete(row: JobDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.job.delete({ id: row.id }).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }
}
